import React, {useState, useEffect, useRef, useCallback} from 'react';

import {Chart, CategoryScale, LineElement, LinearScale, PointElement, Title, TimeScale, Tooltip} from 'chart.js';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';

import {ReactComponent as Close} from './close.svg';
import './History.css';

interface HistoryProps {
  id: string;
  count: number;
  pricePerSqm: number;
  resolution: number;
  onClose: () => void;
}

interface Cache {
  [id: string]: number[][];
}
const cache: Cache = {};

export function History(props: HistoryProps) {

  const [history, setHistory] = useState<number[][]>([]);
  const load = useCallback(async () => {
    if (cache[props.id]) {
      setHistory(cache[props.id]);
      return;
    }
    const url = `history/${props.resolution}/${props.id}.json`;
    const response = await fetch(url).then(r => r.json());
    cache[props.id] = response;
    setHistory(response);
  }, [props.id, props.resolution]);

  Chart.register(
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    TimeScale,
    Tooltip
  );
  useEffect(() => {
    setHistory([]);
    load();
  }, [props.id, load]);

  const data = history.map(i => ({x: new Date(i[0]), y: i[1]}));
  const suggestedMin = Math.min.apply(Math, data.map(p => p.y));
  const suggestedMax = Math.max.apply(Math, data.map(p => p.y));
  const diff = (suggestedMax - suggestedMin) * 2;
  const opts = {
    responsive: true,
    animation: false as const,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'time' as const,
        time: {
          unit: 'day' as const,
        }
      },
      y: {
        suggestedMin: suggestedMin - diff,
        suggestedMax: suggestedMax + diff,
        ticks: {
          callback: (value: string|number) =>`${value} €`
        }
      }
    }
  };

  if (!history.length) {
    return <div className="History">Loading...</div>
  }
  return <div className="History">
    <div className="History__header">
      <div onClick={props.onClose}><Close /></div>
    </div>
    <div className="History__details">
      <div>Price per meter: <b>{props.pricePerSqm} €</b></div>
      <div>Number of ads: <b>{props.count}</b></div>
    </div>
    <div className="History_wrap">
      <div className="History__chart">
        { history.length ?
          <Line
            data={{
              datasets: [{
                label: 'Price per sqm',
                borderColor: 'rgb(54, 162, 235)',
                pointStyle: 'circle',
                pointRadius: 2,
                pointHoverRadius: 15,
                data
              }]
            }}
            options={opts}/>
          : <>Loading...</>
        }
      </div>
    </div>
  </div>;
}
