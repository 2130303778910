import React from 'react';
import './App.css';

import ApartmentsHeatmap from './components/ApartmentsHeatmap';

function App() {
  return (
    <div className="App">
      <ApartmentsHeatmap />
    </div>
  );
}

export default App;
