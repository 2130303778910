import React from 'react';
import {ReactComponent as Close} from './close.svg';
import './Disclaimer.css';

interface DisclaimerProps {
  onClose: () => void;
}

export function Disclaimer(props: DisclaimerProps) {
  return <div className="Disclaimer">
    <div className="Disclaimer__header">
      <div onClick={props.onClose}><Close /></div>
    </div>
    <div className="Disclaimer__details">
      Data is based on asking price in public advertisements. <a href="disclaimer.html">Read disclaimer</a>.
    </div>
  </div>;

}
